<template>
  <b-card>
    <ValidationObserver>
      <form @submit.prevent="saveItem">
        <b-row>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formProvider.businessName"
                  placeholder="Nama Provider"
                />
                <!-- <small
                    v-for="(validation, index) in validations"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  > -->
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <validation-provider
                name="email"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formProvider.providerEmail"
                  placeholder="jane@suwun.co.id"
                />
                <!-- <small
                    v-for="(validation, index) in validations"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  > -->
              </validation-provider>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="phone">Phone Number:</label>
              <validation-provider
                name="phone"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="phone"
                  type="number"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formProvider.providerMsisdn"
                  placeholder="Nomor Telepon"
                />
                <!-- <small
                    v-for="(validation, index) in validations"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  > -->
              </validation-provider>
            </div>
          </b-col>
        </b-row>

        <b-col cols="12" class="p-0 mt-2">
          <button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            :disabled="isLoading"
          >
            {{ isLoading ? "Submitting..." : "Submit" }}
          </button>
          &nbsp; &nbsp;
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Cancel
          </a>
        </b-col>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formProvider: {
        businessName: "",
        providerEmail: "",
        providerMsisdn: "",
      },
      validations: "",
      // Must be an array reference!
    };
  },
  created() {
    this.loadUser();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    loadUser() {
      const params = this.$route.params.id;
      this.$http.get("/admin/provider/" + params).then((response) => {
        this.formProvider = response.data.data;
      });
    },
    saveItem() {
      const params = this.$route.params.id;
      this.isLoading = true;
      const payload = new URLSearchParams();
      payload.append("businessName", this.formProvider.businessName);
      if (this.formProvider.providerMsisdn) {
        payload.append("providerMsisdn", this.formProvider.providerMsisdn);
      }
      if (this.formProvider.providerEmail) {
        payload.append("providerEmail", this.formProvider.providerEmail);
      }
      this.$http
        .put(`/admin/provider/${params}`, payload.toString())
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Provider sukses diupdate!");
          this.$router.push({ name: "providers" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
            // errorNotification(this, "Email", this.validations.email.join(" "));
            // errorNotification(
            //   this,
            //   "No Telepon",
            //   this.validations.msisdn.join(" ")
            // );
            // errorNotification(
            //   this,
            //   "Password",
            //   this.validations.password.join(" ")
            // );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
